@font-face {
	font-family: "Brandon-Grotesque";
	src: local("MyFont"), url(./fonts/BrandonGrotesque-Bold.ttf) format("woff");
}

@font-face {
	font-family: "Montserrat-Regular";
	src: local("MyFont"), url(./fonts/Montserrat-Regular.ttf) format("woff");
}

body {
	margin: 0;
	font-family: "Brandon-Grotesque";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
