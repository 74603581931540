.App {
	font-family: Brandon-Grotesque;
	text-align: center;
        width: 100%;
	font-size: calc(10px + 2vmin);
	background: #343439;
        overflow-y: auto;
}

.App-logo {
	max-width: 180px;
	margin-top: 20px;
	margin-bottom: 20px;
	pointer-events: none;
}

.App-link {
	color: rgb(112, 76, 182);
}

.App-link {
	color: #61dafb;
}

.Card {
	color: white;
	background: #343439;
}
