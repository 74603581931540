.row {
	display: flex;
	align-items: center;
	justify-content: center;
}

.row:not(:last-child) {
	margin-bottom: 16px;
}

.value {
	font-size: 78px;
	padding-left: 16px;
	padding-right: 16px;
	margin-top: 2px;
}

.button {
	appearance: none;
	background: none;
	font-size: 32px;
	padding-left: 12px;
	padding-right: 12px;
	outline: none;
	border: 2px solid transparent;
	color: rgb(112, 76, 182);
	padding-bottom: 4px;
	cursor: pointer;
	background-color: rgba(112, 76, 182, 0.1);
	border-radius: 2px;
	transition: all 0.15s;
}

.QR {
	margin-top: 20px;
	margin-bottom: 20px;
}

.success {
	color: #5fea75;
}

.error {
	color: red;
}

.message {
	font-family: "Montserrat-Regular";
	font-size: 16pt;
        margin-left: 30px;
        margin-right: 30px;
}

.messageLarge {
	font-family: "Montserrat-Regular";
	font-size: 24pt;
        margin-left: 30px;
        margin-right: 30px;
}

.textbox {
	font-size: 32px;
	padding: 2px;
	width: 64px;
	text-align: center;
	margin-right: 8px;
}

.button:hover,
.button:focus {
	border: 2px solid rgba(255, 255, 255, 0.5);
}

.button {
	font-family: "Brandon-Grotesque";
	border-radius: 4rem;
	border: 2px solid rgba(52,52,57, 1.0);
	color: white;
	font-size: 20px;
	background: linear-gradient(45deg, #269eb5, #26becd);
	height: 40px;
	padding-top: 4px;
	padding-left: 40px;
	padding-right: 40px;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 200px;
}

.buttonDevice {
        width: 250px;
}

.button:disabled {
	background: grey;
}

.stateValue {
	font-size: 14px;
}

.label {
	font-family: "Montserrat-Regular";
	font-size: 16pt;
}

.wordEntry {
	margin-bottom: 20px;
}

.wordInput {
	font-family: "Montserrat-Regular";
	font-size: 20px;
	height: 32px;
	width: 188px;
	text-align: center;
	position: relative;
	top: 2px;
	padding-left: 6px;
	padding-right: 6px;
	border-radius: 6px;
	border: none;
}

.loader {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
}

.deviceBox {
        display: flex;
        flex-direction: column;
        align-items: center;
}

.deviceImage {
        width: 150px;
        height: auto;
}

.deviceImage img {
        max-width: 100%;
}

.deviceImage img:hover {
        cursor: pointer;
}
